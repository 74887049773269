import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { FilterChipTemplate } from './FilterChipTemplate';
import { rem } from 'polished';

export const FilterChipStyled = styled(FilterChipTemplate)`
    background: none;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: ${rem(8)};
    margin: ${rem(8)} 0;
    height: ${rem(32)};
    padding: 0;

    svg {
        width: ${rem(32)};
        height: ${rem(32)};
    }

    &.withBorder {
        @media (min-width: ${breakpoints.sm}px) {
            padding-right: ${rem(40)};
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
  
    .label {
        text-decoration: underline;
    }

    .badge {
        background: ${({ theme }) => theme.colors.black};
        padding: 0 ${rem(10)};
        height: ${rem(22)};
        color: ${({ theme }) => theme.colors.white};
        text-decoration: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:hover,
        &:focus-visible {
            color: ${({ theme }) => theme.colors.white};
            text-decoration: unset;
        }
    }

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
