import styled from 'styled-components';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledAP = styled(FilterChipStyled)`
    font-size: ${({ theme }) => theme.fontSizes.textSupBase};

    .badge {
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
    }
`;
