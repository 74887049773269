import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledDS = styled(FilterChipStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h5};

    .badge {
        font-size: ${({ theme }) => theme.fontSizes.textSmall};
    }
`;
