import { FilterChipProps } from '.';
import React, { FC } from 'react';
import { IconTemplate } from '@components/Icon/IconTemplate';

export const FilterChipTemplate: FC<FilterChipProps> = ({
    className,
    index,
    onClick,
    iconName,
    customClassName,
    countOfSelectedFilters,
    label,
    dataId,
}) => {
    return (
        <button
            key={index}
            className={`${className} ${customClassName} ${countOfSelectedFilters > 0 ? 'selected' : ''}`}
            onClick={onClick}
            data-testid={dataId}
        >
            {iconName && <IconTemplate name={iconName} />}
            <span className="label">{label}</span>
            {countOfSelectedFilters > 0 && <span className="badge">{countOfSelectedFilters}</span>}
        </button>
    );
};
