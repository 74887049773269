import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledOV = styled(FilterChipStyled)`
    font-size: ${({ theme }) => theme.fontSizes.h4};

    .badge {
        font-size: ${({ theme }) => theme.fontSizes.h6};
    }

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.black};
    }
`;
