import styled from 'styled-components';
import { FilterBoxStyled } from '../FilterBoxStyled';
import { rem } from 'polished';

export const FilterBoxStyledDS = styled(FilterBoxStyled)`
    .header {
        border-color: ${({ theme }) => theme.colors.grey16};
        padding: ${rem(24)} ${rem(5)};

        .title {
            font-size: ${({ theme }) => theme.fontSizes.h4};
            text-transform: uppercase;
        }

        .closeButton {
            font-size: ${({ theme }) => theme.fontSizes.h3};
        }
    }

    .energy,
    .vehicleTypes,
    .gear {
        margin: ${rem(24)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey16};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        padding-bottom: ${rem(8)};
    }

    .priceRange {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey16};
        padding: 0 0 ${rem(24)} 0;

        > span {
            font-size: ${rem(20)};
            padding-bottom: ${rem(20)};
            display: block;
        }
    }
`;
