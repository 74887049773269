import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import FilterBoxTemplate from './FilterBoxTemplate';
import { rem } from 'polished';

export const FilterBoxStyled = styled(FilterBoxTemplate)`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: ${({ theme }) => theme.colors.white};
    top: 0;
    right: 0;
    color: ${({ theme }) => theme.colors.black};
    z-index: 99999999;
    padding: ${rem(14)} ${rem(32)};
    overflow-x: hidden;

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(480)};
        height: 100vh;
    }

    &.isOpen {
        transform: translateX(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }

    &.isClose {
        transform: translateX(100%);
        opacity: 0;
        transition: transform 1s, opacity 1s;
    }

    .error {
        color: ${({ theme }) => theme.colors.danger};
        padding: ${rem(12)};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey7};
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        text-align: center;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: ${({ theme }) => theme.fontSizes.textMedium};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey7};
        padding: ${rem(19)} 0;
        position: sticky;
        top: -${rem(20)};
        background: ${({ theme }) => theme.colors.white};
        z-index: 999;

        .title {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .closeButton {
            font-size: ${({ theme }) => theme.fontSizes.h5};
        }
    }

    .energy,
    .vehicleTypes,
    .gear {
        margin: ${rem(19)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey7};
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        > span {
            display: block;
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            padding-bottom: ${rem(10)};
        }

        .disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .priceRange {
        padding: ${rem(19)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey7};
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        > span {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};

            @media (max-width: ${breakpoints.sm}px) {
                padding-bottom: ${rem(15)};
                display: block;
            }
        }
    }

    .location {
        padding-bottom: ${rem(19)};
    }

    .location,
    .colors,
    .engines,
    .grades {
        margin: ${rem(19)} 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey7};
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        > span {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            padding-bottom: ${rem(15)};
            display: block;
        }
    }

    .colors {
        padding-bottom: ${rem(19)};
    }

    .button {
        padding: ${rem(19)} 0;
        display: flex;
        justify-content: space-between;
        position: sticky;
        bottom: -${rem(20)};
        background: ${({ theme }) => theme.colors.white};
        z-index: 999;
    }

    .loading {
        opacity: 0.4;
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 45%;
    }
`;
